<template>
    <div>
        <div class="header">
            <span @click="$emit('CarCloes')"></span>
            <span>空车</span>
            <span @click="islogout">退出</span>
        </div>
        <div class="box">
            <div class="liImage">
                <p>请拍摄拖车里程表：</p>
                <div>
                    <UploadImg ref="business_license" :maxCount="1" />
                </div>
                <p>
                    <van-field v-model="record" type="number"  onKeypress="return(/[\d\.]/.test(String.fromCharCode(event.keyCode)))" label-width="65%" label="请填写拖车起运里程表数字：" placeholder="填写里程表数字"  />
                </p>
                <div>
                    请交车人签字：
                    <br>
                    <van-button type="warning" @click="chu">清除</van-button>
                    <div v-if="qianming" class="img"> 
                    
                        <van-image  :src="qianming" />
                    </div>
                    <div  v-if="!qianming">
                        <signature ref="tureRef" @isFile="isFile"  />
                    </div>

                </div>
            </div>
            <div>
                <van-button v-if="isType == 'ShenT'||isType =='TuoT'  " type="primary" size="large" @click="submit">提交</van-button>
                <van-button v-if="isType == 'ShenK' || isType == 'TuoK'" type="primary" size="large" @click="col">关闭</van-button>
              
            </div>
        </div>
    </div>
</template>

<script>
import UploadImg from '@/components/upload_img'
import signature from '@/components/signature'
import { encipherMent, encryptDecode } from '@/utils/encrypt'
import {information,ExecuteConsignment} from '@/api/send.js'
import {is_post} from '@/Http/api'
import {checkInformationSubmit,checkInformation} from '@/api/audit.js'
    export default {
        components: {
            UploadImg,signature,
        },
        data() {
            return {
                value:'',
                isbase64:"",
                record:"",
                end_position:"",
                tableData:[],
                pageId:"",
                phone:"",
                contact_person:"",
                isType:'',
                qianming:'',
            }
        },
        methods: {
            //清除画布签名
            chu(){
                if(this.qianming){

                    this.qianming = ''
                }else{

                    this.$refs.tureRef.clearSignImg()
                }
            },
            col(){
                if(this.isType == 'ShenK'){
                    this.$emit('CarCloeskong')
                }else{
                    this.$emit('CarCloes')
                }
               
            },
            async isFile(e){
               
                if(this.isType == 'TuoT' ){
                    let obj = {
                    starting_drive_image:this.$refs.business_license.imagesUrlList[0]?this.$refs.business_license.imagesUrlList[0]:'',
                    starting_trip_record:this.record,
                    owner_signature:e,
                    id: this.pageId,
                }
                    const {data} =  await ExecuteConsignment(obj).then(res=>res)
                    this.$emit('CarCloesChange')
                    this.$toast.success(data.msg)
                }else if(this.isType == 'ShenT' ){
                    let obj = {
                        ending_drive_image:this.$refs.business_license.imagesUrlList[0]?this.$refs.business_license.imagesUrlList[0]:'',
                        ending_trip_record:this.record,
                        means_signature:e,
                        id: this.pageId,
                    }
                    const {data} =  await checkInformationSubmit(obj).then(res=>res)
                    this.$emit('CarCloeskong')
                    this.$toast.success(data.msg)
                }
                // checkInformationSubmit
               
            },
            set(item,type){
                this.pageId = item.id
                this.isType = type
                if(type == 'ShenT' || type =='TuoT'){

                }else{

                    if(item.transport_status==2){
                        this.SetData(item,type)
                    }
                }
            },
            kong(){
                this.record = ''
                this.qianming = ''

                this.$nextTick(()=>{
                    this.$refs.business_license.images = []
                    this.$refs.business_license.imagesUrlList = []
                })
            },
            async SetData(item,type){
                // if(type == 'TuoK'){
                //     const {data} = await information({id:item.id}).then(res=>res)
                //     this.record = data.data.ending_trip_record
                //     this.$refs.business_license.images = [{url:data.data.ending_drive_image}]
                //     this.qianming = data.data.means_signature
                // }else if(type == 'ShenK'){
                    const {data} = await checkInformation({id:item.id}).then(res=>res)
              
                    this.qianming = data.data.means_signature
                    this.$refs.business_license.images = [{url:data.data.ending_drive_image}]
                    this.record = data.data.ending_trip_record

                // }
            },
           submit(){
                if(this.qianming){
                    this.isFile(this.qianming)
                }else{
                    this.$refs.tureRef.saveSignImg()
                }
            },
        },
    }
</script>

<style lang="scss" scoped>
.header {
    position: fixed;
    top: 0;
    z-index: 9;
    width: calc(100% - 40px);
    padding: 0 20px;
    background-color: cadetblue;
    height: 1rem;
    line-height: 1rem;
    color: white;
    font-size: 0.4rem;
    display: flex;
    justify-content: space-between;
    span {
        display: inline-block;
    }
    span:nth-child(1) {
        width: 30px;
        height: 30px;
        background-image: url(../../assets/u39.png);
        background-size: 100%;
        margin-top: 8px;
    }
}
.box{
    margin-top: 55px;
    padding: 10px;
    .table_box{
        border-right:1px solid #000;border-bottom:1px solid #000;
        tr{
            display: flex;
        }
        td{
            border-left:1px solid #000;
            border-top:1px solid #000;
            flex:1;
            display: flex;
            justify-content: center;
            align-items: center;
        }
        th{border-left:1px solid #000;border-top:1px solid #000; flex:1;}
       
    }
}
.van-cell{
    padding: 0;
}
.liImage{
    margin-top: 20px;
}
.img{
    background: #eee;
    position: relative;
}
</style>