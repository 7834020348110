<template>
    <div>
        <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
            <van-list
                v-model="loading"
                :finished="finished"
                finished-text="没有更多了"
                :immediate-check="false"
                @load="onLoad"
                :offset="200"
            >
            <div class="content-infp" v-for="item in list" :key="item">
                <p>
                    <span>预约拖车时间：{{item.appointment_tuo_time}}</span>
                </p>
                <p>
                    <span>拖车地点：{{item.appointment_locationDetail}}</span>
                </p>
                <p>
                    <span>拖车号牌：{{item.carNumberPlate}}</span>
                </p>
                <p>
                    <span>车架号码：{{item.vin}}</span>
                </p>
                <p v-if="item.transport_remark&&item.transport_status==2">
                    <span>审核备注：{{item.transport_remark}}</span>
                </p>
                <div class="btn-anniu" v-if="status==1">
                    <van-button type="primary" @click="openPhone(item)">电话呼叫</van-button>
                    <!-- <van-button type="primary">主要按钮</van-button> -->
                    <van-button type="primary" @click="openGather(item,0)">信息采集</van-button>
                </div>
                <div class="btn-anniu" v-if="status==2">
                    <van-button type="primary"  @click="openPhone(item)">电话催审</van-button>
                    <van-button type="primary"  @click="openGather(item,1)">修改信息</van-button>
                    <!-- <van-button type="primary">开始拖运</van-button> -->
                </div>
                <div class="btn-anniu" v-if="status==3">
                    <van-button type="primary" v-if="item.transport_status == 2" @click="openGather(item,2)">修改信息</van-button>
                    <!-- <van-button type="primary" v-if="item.transport_status == 2">禁止运输</van-button> -->
                    <van-button type="primary" v-if="item.transport_status == 2"  @click="kongtuo(item)">空车驶回</van-button>
                    <van-button type="primary" v-if="item.transport_status == 1" @click="towage(item)">开始拖运</van-button>
                </div>

                <div class="btn-anniu" v-if="status==5">
                    <van-button type="primary" @click="InTheAuditChange(item,2)">查看详情</van-button>
                </div>
            </div>
            </van-list>
        </van-pull-refresh>
        <van-popup
            v-model="show"
            :closeable="false"
            :close-on-click-overlay="false"
            position="bottom"
            :style="{height: '100%',}"
            >
            <TrailerInfo @CarCloes="CarCloes" @CarCloesChange="CarCloesChange" ref="InfoRef" status="isTuoYun" />
        </van-popup>

        <van-popup
        v-model="kongtuoshow"
        :closeable="false"
        :close-on-click-overlay="false"
        position="bottom"
        :style="{height: '100%',}"
        >
        <NoConsignment @CarCloes="CarCloes" ref="kongtuo" @CarCloesChange="CarCloesChange" />
    </van-popup>

    </div>
</template>

<script>
import {derverToArrive,ExecuteConsignment} from '@/api/send'
import {Toast} from 'vant'
import NoConsignment from '@/components/NoConsignment'
import TrailerInfo from '@/components/TrailerInfo'

    export default {
        props:['status'],
        components: {
            TrailerInfo,
            NoConsignment
        },
        data() {
            return {
                refreshing: false,//上啦的
                loading: false,//下拉等待圈圈
                finished: true,//下拉的状态 
                list:[],
                page:0,
                show:false,//弹窗
                kongtuoshow:false,//空托
            }
        },
        methods: {
            InTheAuditChange(item,num){
                if(item.transport_status == 1){
                    this.$router.push({path:'/InTheAudit',query:{id:item.id,ids:num,url:'tuoyun'}})
                }else if(item.transport_status == 2){
                    this.kongtuoshow = true
                    this.$nextTick(()=>{
                        this.$refs.kongtuo.set(item,'TuoK')
                    })
                }
            },
            //关闭弹窗
            CarCloes(){
                this.show = false
                this.kongtuoshow = false
                this.lodData()
            },
            CarCloesChange(){
                this.kongtuoshow = false
                this.$emit('changeActive',3)
            },
            towage(i){
                this.show = true
                this.$nextTick(()=>{
                    this.$refs.InfoRef.getInfo(i.id)
                })
            },
            kongtuo(item){
                this.kongtuoshow = true
                this.$nextTick(()=>{
                    this.$refs.kongtuo.set(item,'TuoT')
                    this.$refs.kongtuo.kong()
                })
            },
            // 联系电话
            openPhone(val){
                window.location.href = 'tel://' + val.phone
            },
            //采集信息
            openGather(val,num){
                this.$router.push({path:'/InfoGather',query:{idx:num,id:val.id}})
            },
              //加载 上拉
              onRefresh() {
                this.page = 0
                        // 清空列表数据
                this.list = []
                this.finished = false;
                this.refreshing = false
                // 重新加载数据
                // 将 loading 设置为 true，表示处于加载状态
                this.loading = false;
                // this.onLoad();
            },
            //加载
             onLoad(){
                let this_ = this
                let toast = Toast.loading({
                    duration: 0, // 持续展示 toast
                    forbidClick: true,
                    message: "加载中..."
                });
                setTimeout(async()=>{
                    try{
                        let page = this_.page + 1
                        this_.page = page
                    let obj = {
                        status:this.status,
                        page
                    }
                    const {data} = await derverToArrive(obj).then(res=>res)
                    toast.clear();
                    if(data.data.data.length==0){
                            //this_.list = [] 
                            this_.finished = true;
                            this_.refreshing = false
                        }else{
                            let arr = data.data.data
                            arr.forEach(element => {
                                this_.list.push(element)
                            });
                            if(this_.list.length >= data.data.total){
                                this_.finished = true;
                                this_.refreshing = false
                            }else{
                                this_.finished = false;
                                // this_.refreshing = false
                            }
                        }
                        this_.loading = false;
                    }
                    catch{
                        this_.finished = true;
                        this_.refreshing = false
                        toast.clear();
                        this_.$toast.fail('请求出错！')
                    }
                    
                },1000)
            },
            lodData() {
                // console.log(this.status)
                this.page=0
                this.list = [] 
                this.onLoad()
            }
        },
    }
</script>

<style lang="scss" scoped>
.van-list{
    min-height: 40vh;
}
.content-infp{
    margin-top: 10px;
    padding: 10px;
    border-radius: 8px;
    border: 1px solid #eee;
    .btn-anniu{
        text-align: center;
        .van-button{
            margin: 0 10px;
        }
    }
}
</style>