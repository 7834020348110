<template>
    <div>
        <Head :title="'车辆拖运'" :out="'SecurityManagement'" />
        <van-tabs class="vanTabs" v-model="active" @click="ChangeHan($event)" >
            <van-tab title="待抵达"> <sendList ref="listRef0" status="1" ></sendList> </van-tab>
            <van-tab title="待审核"> <sendList ref="listRef1" status="2" ></sendList>  </van-tab>
            <van-tab title="待拖运"> <sendList ref="listRef2" status="3" @changeActive="changeActive"></sendList>  </van-tab>
            <van-tab title="车辆在途"> <sendList ref="listRef3" status="4" ></sendList>  </van-tab>
            <van-tab title="已完成"> <sendList ref="listRef4" status="5" ></sendList>  </van-tab>
        </van-tabs>
    </div>  
</template>

<script>
import Head from '@/components/Head'
import sendList from './sendList'
    export default {
        components: {
            Head,sendList
        },
        data() {
            return {
                active:0,
            }
        },
        mounted () {
            if(this.$route.query.active==0){
                this.active = 0
            }else if(this.$route.query.active==1){
                this.active = 1
            }else if(this.$route.query.active==2){
                this.active = 2
            }else if(this.$route.query.active==3){
                this.active = 3
            }else if(this.$route.query.active==4){
                this.active = 4
            }
            this.ChangeHan()
        },
        methods: {
            changeActive(n){
                this.active = 3
                this.ChangeHan()
            },
            ChangeHan() {
                if(this.active==0){
                    this.$nextTick(()=>{
                        this.$refs.listRef0.lodData()
                    })
                }else if(this.active==1){
                    this.$nextTick(()=>{
                        this.$refs.listRef1.lodData()
                    })
                }else if(this.active==2){
                    this.$nextTick(()=>{
                        this.$refs.listRef2.lodData()
                    })
                }else if(this.active==3){
                    this.$nextTick(()=>{
                        this.$refs.listRef3.lodData()
                    })
                }else if(this.active==4){
                    this.$nextTick(()=>{
                        this.$refs.listRef4.lodData()
                    })
                }
            }
        },
    }
</script>

<style lang="scss" scoped>
.vanTabs{
    margin-top: 50px;
}
</style>
